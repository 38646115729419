import PropTypes from "prop-types";
import React, { useMemo } from "react";

import useHideOnScrollEffect from "~hooks/use_hide_on_scroll_effect/use_hide_on_scroll_effect";

const Context = React.createContext({});

export function HideOnScrollContextProvider({ children }) {
  const { isScrolledToTop, shouldHideHeader } = useHideOnScrollEffect();

  const value = useMemo(() => {
    return {
      isScrolledToTop,
      shouldHideHeader,
    };
  }, [isScrolledToTop, shouldHideHeader]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

HideOnScrollContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Context;
