/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable react/jsx-filename-extension */
import React from "react";
import balanceText from "balance-text";
import "normalize.css";
import { PortalContainer } from "~components/utils/portal/portal";
import "./src/global.module.scss";
import { HideOnScrollContextProvider } from "./src/hide_on_scroll_context";
import "./src/typography.scss";
import "./src/utils/settings/settings.scss";

export const wrapRootElement = ({ element }) => {
  return (
    <PortalContainer portalId="modal">
      <HideOnScrollContextProvider>{element}</HideOnScrollContextProvider>
    </PortalContainer>
  );
};

// eslint-disable-next-line import/prefer-default-export
export const shouldUpdateScroll = ({ routerProps }) => {
  if (routerProps.location.state !== null) {
    const { disableScrollUpdate } = routerProps.location.state;
    return !disableScrollUpdate;
  }
  return null;
};

export function onClientEntry() {
  balanceText();
}

export function onRouteUpdate() {
  balanceText.updateWatched();
}
