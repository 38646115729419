import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

const contexts = {};

// Empty 'placeholder' context that is used when context withon `contexts`
// object is not yet set (e.g during hot reloads)
const placeholderContext = React.createContext(undefined);

// Creates a new Context for passed portalId
// Unsets/destroys context when unmounting componet using usePortalIdContext
function usePortalIdContext(portalId) {
  const context = useMemo(() => {
    if (!contexts[portalId]) {
      contexts[portalId] = React.createContext({});
    }
    return contexts[portalId];
  }, [portalId]);

  // Ensure context is removed when component is unmounted
  useEffect(() => {
    return () => {
      contexts[portalId] = null;
    };
  }, [portalId]);

  return context;
}

// PortalContainer renders portal 'container' div before passed children and
// provides a ref to this 'container' via a Context unique to portalId
export function PortalContainer({ portalId, children }) {
  const [ref, setRef] = useState();
  const Context = usePortalIdContext(portalId);

  return (
    <Context.Provider value={ref}>
      <div className={`__portal-${portalId}`} ref={setRef} />
      {children}
    </Context.Provider>
  );
}

PortalContainer.propTypes = {
  portalId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

// Renders into portal 'container' element as specified/created by
// <PortalContainer> for specific portalId
export default function Portal({ portalId, children }) {
  if (!contexts[portalId]) {
    // eslint-disable-next-line no-console
    console.warn(`Portal context for id "${portalId}" doesn't exist`);
  }

  // Fallback to using `placeholderContext` if `contexts[portalId]` is not yet
  // set as useContext cannot be called conditionally and a valid context must
  // be passed
  const ref = useContext(contexts[portalId] || placeholderContext);

  if (ref) {
    return ReactDOM.createPortal(children, ref);
  }

  // Return empty <div /> to avoid SbEditable errors when no ref is found
  return <div />;
}

Portal.propTypes = {
  portalId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
