import { createContext, useCallback, useEffect, useRef, useState } from "react";
import throttle from "lodash.throttle";

export default function useScrollListener() {
  const [data, setData] = useState({
    x: 0,
    y: 0,
    lastX: 0,
    lastY: 0,
  });

  const handleScroll = useCallback(() => {
    setData((currentState) => {
      return {
        x: window.scrollX,
        y: window.scrollY,
        lastX: currentState.x,
        lastY: currentState.y,
      };
    });
  }, []);

  const throttledHandleScroll = useRef(
    throttle(() => {
      return handleScroll();
    }, 50)
  );

  useEffect(() => {
    const eventListenerCallback = throttledHandleScroll.current;
    window.addEventListener("scroll", eventListenerCallback, { passive: true });

    return () => {
      eventListenerCallback.cancel();
      window.removeEventListener("scroll", eventListenerCallback);
    };
  }, []);

  return data;
}

export const ScrollContext = createContext(null);
