import { useCallback, useEffect, useRef, useState } from "react";
import useScrollListener from "~hooks/use_scroll_listener/use_scroll_listener";

const MAX_HEIGHT = 80;

export default function useHideOnScrollEffect() {
  const scroll = useScrollListener();

  // state variables returned to context provider
  const [shouldHideHeader, setShouldHideHeader] = useState(false);
  const [isScrolledToTop, setIsScrolledToTop] = useState(false);

  // refs to store scroll position when the direction changes
  const cachedScrollPosAtDirectionChange = useRef(0);
  const cachedIsScrollingDownwards = useRef(false);

  const handleScroll = useCallback(({ isScrollingDownwards, scrollPos }) => {
    // update refs *only* on change direction
    if (cachedIsScrollingDownwards.current !== isScrollingDownwards) {
      cachedIsScrollingDownwards.current = isScrollingDownwards;
      cachedScrollPosAtDirectionChange.current = scrollPos.y;
    }

    // should always show header at top
    // set state var for context provider and return early
    if (
      !document.documentElement.classList.contains("isScrollLockedStyle") &&
      scrollPos.y < MAX_HEIGHT
    ) {
      setIsScrolledToTop(true);
      return setShouldHideHeader(false);
    }
    // otherwise set state var and continue
    setIsScrolledToTop(false);

    // calculate how far user has scrolled since last direction change
    const currentScrollOffsetFromCachedScrollPosition =
      cachedScrollPosAtDirectionChange.current - scrollPos.y;

    // handle scrolling upwards
    // wait for 2x full header scroll before showing header
    if (
      !isScrollingDownwards &&
      currentScrollOffsetFromCachedScrollPosition > MAX_HEIGHT * 2
    ) {
      return setShouldHideHeader(false);
    }

    // handle scrolling downwards
    // wait for full header scroll before hiding header
    if (
      isScrollingDownwards &&
      currentScrollOffsetFromCachedScrollPosition < MAX_HEIGHT * -1
    ) {
      return setShouldHideHeader(true);
    }

    return null;
  }, []);

  // const debouncedHandleScroll = useRef(
  //   debounce(({ ...args }) => {
  //     return handleScroll({ ...args });
  //   }, 10)
  // );

  useEffect(() => {
    return handleScroll({
      isScrollingDownwards: scroll.y > scroll.lastY,
      scrollPos: scroll,
    });
  }, [handleScroll, scroll]);

  return { isScrolledToTop, shouldHideHeader };
}
